<template>
  <div class="order-management">
    <div style="padding: 8px">
      <h3 style="text-align: center">已付款说明</h3>
      <p>
        1、您付款完成后，在工作时间范围内我们将很快为您出单，单号给出后您可以在牛气官网或相应物流公司官网上查询物流信息。
      </p>
      <p>2、您的货物单号给出后，将通过微信和短信通知给您，请注意查收</p>
      <p>3、有任何物流上的疑问，请咨询客服</p>
    </div>
    <div style="text-align: center; margin-top: 30px">
      <button class="nq-button">关闭</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'login',
  data() {
    return {
      showDetail: false
    }
  },

  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
@import url("./order.less");
</style>
